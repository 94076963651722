/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid, GridRow, GridColumn } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      id="footer"
      aria-label="Footer"
    >
      <Container>
        <div id="footer-main">
          <Grid columns={4}>
            <GridColumn textAlign='left'>
              <List>
                <List.Header>
                  <header as="h3">About</header>
                </List.Header>
                <List.Content>
                  <List.Item>
                    <UniversalLink href="#">Who we are</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="#">Our Team</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="#">Collaborators and funders</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="#">Governance</UniversalLink>
                  </List.Item>
                </List.Content>
              </List>
            </GridColumn>
            <GridColumn textAlign='left'>
              <List>
                <List.Header>
                  <header as="h3">Nav</header>
                </List.Header>
                <List.Content>
                  <List.Item>
                    <UniversalLink href="/research-to-policy">Research to policy</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/teaching-learning">Teaching & Learning</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/teaching-learning/projects">Projects</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/public-engagement">Public engagement</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/resources">Resources</UniversalLink>
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="/save-uhc/about">SAVE-UHC</UniversalLink>
                  </List.Item>
                </List.Content>
              </List>
            </GridColumn>
            <GridColumn textAlign='left'>
              <List>
                  <List.Header>
                    <header as="h3">Quick Links</header>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="/about/contact-us">Contact us</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/privacy-policy">Privacy policy</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/terms-conditions">Terms & conditions</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <hr/>
                    </List.Item>
                    <List.Item>
                      <List horizontal>
                        <List.Item><UniversalLink href="#"><i class="bi bi-linkedin"></i></UniversalLink></List.Item>
                        <List.Item><UniversalLink href="#"><i class="bi bi-twitter-x"></i></UniversalLink></List.Item>
                        <List.Item><UniversalLink href="#"><i class="bi bi-facebook"></i></UniversalLink></List.Item>
                      </List>
                    </List.Item>
                  </List.Content>
                </List>
            </GridColumn>
            <GridColumn></GridColumn>
            <GridColumn textAlign='right' verticalAlign='bottom'>
              <List>
                <List.Content>
                  <List.Item>
                    <FormattedMessage
                      id="{copyright} Priceless SA {current_year}"
                      defaultMessage="{copyright} Priceless SA {current_year}"
                      values={{
                        copyright: (
                          <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
                        ),
                        current_year: new Date().getFullYear(),
                      }}
                    />
                  </List.Item>
                  <List.Item>
                    <UniversalLink href="#top"><i class="bi bi-arrow-up-circle-fill"></i></UniversalLink>
                  </List.Item>
                </List.Content>
              </List>
            </GridColumn>
          </Grid>
        </div>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
